import { toLocaleCurrency } from "@/utils/strings";
import { toRelationString } from "@/utils/transforms";
import { toLocaleDate } from "@/utils/dates";
import {
  toInfoData,
  // toTooltipTask,
  toGroupedTooltipTask,
  getTaskableLabel,
} from "@/utils/transforms";
import moment from "moment";
moment.locale("it");
import store from "@/store";

// const salesmen = store.getters["auth/salesmen"];
// const councils = store.getters["auth/councils"];
const councils = store.state.auth.councils;
const countries = store.getters["auth/countries"];
// import { mapGetters } from "vuex";
// virtual fields calculus
const communication_icons = {
  "-1": "blank", // non trovato (c'è ma non è usato)
  0: "envelope-open", // E-Mail
  1: "mailbox", // Posta
  // "2": "card-text", // SMS
  2: "phone", // SMS
  // "3": "phone", // Telefono
  3: "telephone", // Telefono
  4: "slash-square", // No Avviso
  5: "file-person", // Tramite Capogruppo
};

const payment_splittings = [
  { value: "0", text: "Uni" },
  { value: "1", text: "Men" },
  { value: "2", text: "Bim" },
  { value: "3", text: "Tri" },
  { value: "4", text: "Qua" },
  { value: "6", text: "Sem" },
  { value: "12", text: "Ann" },
];

const chan_icons = {
  "": "blank", // non trovato (c'è ma non è usato)
  "E-mail": communication_icons[0],
  Posta: communication_icons[1],
  SMS: communication_icons[2],
  Telefono: communication_icons[3],
  "No Avviso": communication_icons[4],
};

const communication_text = [];
communication_text["mailbox"] = "Posta";
communication_text["envelope-open"] = "E-mail";
communication_text["telephone"] = "Telefono";
communication_text["phone"] = "SMS";
communication_text["slash-square"] = "No Avviso";
communication_text["file-person"] = "Tramite Capogruppo";

const db_currency_fields = ["gross"]; // i campi di insurance_payment, gli altri sono tutti nested, quindi vituali

const texts = (field, data) => {
  let value;
  let parts = field.value.split(".");
  let length = parts.length;
  if (field.option) {
    value =
      typeof data[field.option] === "object"
        ? data[field.option].text
        : parts.reduce((acc, part) => acc && acc[part], data);
  } else {
    // // if status && relation, extract info from the relation, filtering by status value:
    // if (field.status != null && field.relation) {
    //   let relationData = data[field.relation];
    //   let repo = data.split("_").join("");
    //   let rd = this.getRelationDataByStatus(
    //     relationData,
    //     `status_${repo}`,
    //     field.status
    //   );
    //   if (rd) {
    //     //found relation data for given status
    //     if (length > 1) {
    //       value = parts.reduce((acc, part) => acc && acc[part], rd);
    //     } else {
    //       value = rd[field.value];
    //     }
    //   }
    // } else {
    if (length > 1) {
      value = parts.reduce((acc, part) => acc && acc[part], data);
    } else {
      value = data[field.value];
    }
    // }
  }
  return value;
};

export const hasBookEntry = (item) => {
  // 0: sospesi
  // 1: arretrati
  // 2: in scadenza (quietanze)
  // 3: acconti
  // 4: manuale
  // return (
  //   item.payment_type.value == 0 && item.payable_type.endsWith("BookEntry")
  // );
  return (
    [0, 3].includes(item.payment_type.value) &&
    item.payable_type.endsWith("BookEntry")
  );
};

export const isType = (type, item) => {
  return item.payment_type.value == type;
};

export const hasInsuranceAncillary = (item) => {
  // return (
  //   item.payment_type.value != 0 &&
  //   item.payable_type.endsWith("InsuranceAncillary")
  // );
  return (
    [1, 2, 4].includes(item.payment_type.value) &&
    item.payable_type.endsWith("InsuranceAncillary")
  );
};

export const hasSameAddress = (item) => {
  let registry;
  let data;
  switch (true) {
    case hasBookEntry(item):
      data = item.payable;
      registry = data.registries[0];
      break;
    case hasInsuranceAncillary(item):
      data = item.payable;
      registry = data.insurance_policy?.registry;
      break;
  }
  const address0 = registry?.correspondences.find(
    (e) => e.status_correspondence.value === 0
  );

  const address1 = registry?.correspondences.find(
    (e) => e.status_correspondence.value === 1
  );

  const hash0 = address0 ? address0.geo_reference : null;
  const hash1 = address1 ? address1.geo_reference : null;
  // console.log(`hash0: ${hash0} hash1: ${hash1}`);
  return hash0 === hash1;
};

const getCoordinator = (group) => {
  return group.coordinator.find((c) => c.pivot.registry_group_id === group.id);
};

const getCoordinatorCommunicationType = (group) => {
  let coordinator = getCoordinator(group);
  let ret = {};
  ret.icon = coordinator
    ? communication_icons[coordinator.pivot.communication_type.value]
    : "blank";
  ret.title = coordinator
    ? `Canale di comunicazione del Capogruppo (${coordinator.title}): ${
        communication_text?.[ret.icon]
      }`
    : "Capogruppo non identificato";
  return ret;
};

export const getAncillary = (item) => {
  let data;
  let ancillary;
  switch (true) {
    case hasBookEntry(item):
      data = item.payable;
      ancillary = data.insurance_ancillaries[0];
      break;
    case hasInsuranceAncillary(item):
      data = item.payable;
      ancillary = data; //.insurance_ancillary;
      break;
  }
  return ancillary;
};

export const getVirtualValue = (virtualField, item) => {
  let ret = "";
  let insurance_payment;
  let data;
  let ancillary;
  let registry;
  let groups;
  let broker;
  let notes;
  let tmp;
  let grp;
  let c;
  let email = false;
  let phone = false;
  let default_chan = false;
  let policy_no_notify = false;
  let hasVariousAccounting = false;
  const task_type = 2; // note
  const communication_type = 5; // capogruppo
  switch (true) {
    case hasBookEntry(item):
      insurance_payment = item;
      data = item.payable;
      registry = data.registries[0];
      groups = data.registries[0].registry_groups;
      broker = data.brokers[0];
      // TODO estrarre le note da accountable_referrer ??? (o il broker o il registry o entrambi)
      notes = (
        registry?.tasks
          ? registry.tasks.filter((e) => e.task_type.value === task_type)
          : []
      ).concat(
        ancillary &&
          ancillary.insurance_policy &&
          ancillary.insurance_policy.tasks
          ? ancillary.insurance_policy.tasks.filter(
              (e) => e.task_type.value === task_type
            )
          : []
      );
      if (!ancillary) {
        // non ho trovato ancillary perchè il Q è del tipo che non ha titolo (...omissis)
        hasVariousAccounting = true;
      }
      break;
    case hasInsuranceAncillary(item):
      insurance_payment = item;
      data = item.payable;
      ancillary = data; //.insurance_ancillary;
      registry = ancillary.insurance_policy?.registry;
      groups = ancillary.insurance_policy?.registry.registry_groups;
      broker = data?.insurance_policy?.broker;
      // TODO estrarre le note da accountable_referrer ??? (o il broker o il registry o entrambi)
      notes = (
        registry?.tasks
          ? registry.tasks.filter((e) => e.task_type.value === task_type)
          : []
      ).concat(
        ancillary &&
          ancillary.insurance_policy &&
          ancillary.insurance_policy.tasks
          ? ancillary.insurance_policy.tasks.filter(
              (e) => e.task_type.value === task_type
            )
          : []
      );
      // .concat(
      //   ancillary &&
      //     ancillary.insurance_policy &&
      //     ancillary.insurance_policy.registry &&
      //     ancillary.insurance_policy.registry.tasks
      //     ? ancillary.insurance_policy.registry.tasks.filter(
      //         (e) => e.task_type.value === task_type
      //       )
      //     : []
      // );
      break;
  }

  if (
    data &&
    ((ancillary && ancillary.insurance_policy) || hasVariousAccounting)
  ) {
    let factor = !hasVariousAccounting
      ? parseInt(ancillary.insurance_policy.payment_splitting.value)
      : 0;
    let insurance_policy = ancillary?.insurance_policy;
    const REGISTRY_ID = registry.id;
    if (!REGISTRY_ID) {
      console.debug("Non c'è registryies in ", data);
    }

    switch (virtualField) {
      case "errors":
        // Polizza con status diverso da 'Perfezionata'
        ret = "-";
        if (insurance_policy && item.payment_type.value === 2) {
          if (insurance_policy.status_policy.value !== 1) {
            ret = `${
              insurance_policy?.status_policy?.text
                ? "Polizza " + insurance_policy.status_policy.text.toLowerCase()
                : "-"
            }`;
          } else if (insurance_policy.attributables.RENW === "N") {
            ret = "Polizza senza tacito rinnovo";
          } else if (
            moment(ancillary.effective_at)
              .subtract(10, "d")
              .isAfter(insurance_policy.covered_at)
          ) {
            ret = "Discordanza data";
          } else if (
            moment(ancillary.effective_at).diff(
              moment(insurance_policy.covered_at),
              "days"
            ) > 9
          ) {
            ret = "Rata precedente non incassata";
          } else if (
            moment(insurance_policy.covered_at).diff(
              moment(ancillary.effective_at),
              "days"
            ) > 9
          ) {
            ret = "Quietanza pagata";
          }
        }
        break;
      case "diff":
        if (isType(2, item)) {
          ret = toLocaleCurrency(
            insurance_payment.gross - ancillary.insurance_policy.gross_premium // / factor
          );
        } else {
          ret = "-";
        }

        break;
      case "ultimo":
        if (isType(2, item)) {
          ret = toLocaleCurrency(ancillary.insurance_policy.gross_premium);
        } else {
          ret = "-";
        }
        break;
      case "perc":
        if (isType(2, item)) {
          ret = perc(insurance_payment, ancillary);
        } else {
          ret = "-";
        }

        break;
      case "covered_at":
      case "expired_at":
        ret = !hasVariousAccounting
          ? moment(ancillary.insurance_policy[virtualField]).format(
              "DD/MM/YYYY"
            )
          : "";
        break;
      case "payment_splitting":
        ret = !hasVariousAccounting ? factor.toString().padStart(2, "0") : "-";
        break;
      case "payment_splitting_text":
        ret = !hasVariousAccounting
          ? payment_splittings.find((e) => e.value == factor.toString()).text
          : "-";
        break;
      case "sharing":
        ret =
          ancillary && ancillary?.insurance_policy?.sharing_type.value != 2
            ? toLocaleCurrency(ancillary.insurance_policy.sharing)
            : null;
        break;
      case "status_policy":
        ret = !hasVariousAccounting
          ? ancillary.insurance_policy.status_policy.text
          : data.various_accountings?.[0]?.pivot?.insurance_policy_number ||
            "-";
        break;
      case "number":
        ret = !hasVariousAccounting
          ? ancillary.insurance_policy[virtualField]
          : data.various_accountings?.[0]?.pivot?.insurance_policy_number ||
            "-";
        break;
      case "branch_risk":
        if (!hasVariousAccounting) {
          ret = `${ancillary.insurance_policy.insurance_risk.risk_branch.code}-${ancillary.insurance_policy.insurance_risk.code}-${ancillary.insurance_policy.insurance_risk.formatted_title}`;
        }
        break;
      case "gross_premium":
        return toLocaleCurrency(ancillary.insurance_policy.gross_premium);
      case "insurer_code":
        ret = ancillary?.insurance_policy
          ? `${ancillary.insurance_policy.insurer.code}`
          : "-";
        break;
      case "branch_and_type":
        // console.log("ancillary: ", ancillary);
        // console.log("hasVariousAccounting", hasVariousAccounting);
        // console.log("data", data);
        ret = !hasVariousAccounting
          ? `${ancillary.insurance_policy.insurance_risk.risk_branch.code}/${
              ancillary.insurance_policy.insurance_risk.code
            }/${getRiskTypeDescription(ancillary.insurance_policy)}`
          : `${
              data.various_accountings?.[0]?.pivot.insurance_risk &&
              data.various_accountings?.[0]?.pivot.insurance_risk.risk_branch
                ? data.various_accountings?.[0]?.pivot.insurance_risk
                    .risk_branch.code
                : "--"
            }/${
              data.various_accountings?.[0]?.pivot.insurance_risk
                ? data.various_accountings?.[0]?.pivot.insurance_risk.code
                : "--"
            }/${
              data.various_accountings?.[0]?.pivot.insurance_risk
                ? data.various_accountings?.[0]?.pivot.insurance_risk.title
                : "--"
            }`;
        // if not "--/--/--"
        if (!ret.replace(/[\s/-]/gm, "")) {
          ret = "-";
        }
        break;
      case "collaborazione":
        ret = "-";
        if (!broker) {
          // console.info(`QID: ${item.id} -> non ha relazione con brokers`);
          return ret;
        }
        ret = toInfoData(broker, "brokerfullname", 1, "\n");
        break;
      case "produttore":
        // CHECK produttore (find su salesmen ha senso solo se veramente arrivano solo broker con status_broker 1)
        // probabilmente nel seeder li mette a caso
        // if (!hasVariousAccounting) {
        //   if (ancillary.broker_id) {
        //     tmp = salesmen.find((s) => s.value === ancillary.broker_id);
        //     ret = tmp ? tmp.text : "";
        //   }
        // } else {
        //   ret = "BOH";
        // }
        ret = "";
        if (!broker) {
          // console.info(`QID: ${item.id} -> non ha relazione con brokers`);
          return ret;
        }
        ret = toInfoData(broker, "broker", 0, "\n");
        break;
      case "mora":
        ret = !hasVariousAccounting ? ancillary.delay_days : "";
        break;
      case "gruppo":
        ret = "-";
        if (!REGISTRY_ID || !groups || !groups.length) return ret;
        if (!hasVariousAccounting) {
          if (ancillary.insurance_policy.registry_group_id) {
            // cerco il code tra i gruppi in registryies..
            if (!registry) {
              return ret;
            }
            // DEBUG
            // DEBUG
            if (!registry.registry_groups) {
              console.debug(
                `>>>  QID: ${item.id} -> contraente ${REGISTRY_ID} non ha relazione coi gruppi`
              );
              registry["registry_groups"] = [];
            }
            tmp = registry;
            if (tmp && tmp.registry_groups && tmp.registry_groups.length) {
              grp = tmp.registry_groups.find(
                (g) => (g.id = ancillary.insurance_policy.registry_group_id)
              );
              if (grp) {
                ret = grp.code;
              }
            }
          } else {
            // cerco il gruppo primary di REGISTRY_ID
            tmp = registry.registry_groups.find(
              (g) =>
                g.pivot.registry_id === REGISTRY_ID &&
                g.pivot.primary.value === "Y"
            );
            if (tmp) {
              ret = tmp.code;
            } /* else {
              console.info(
                `contraente ${REGISTRY_ID} non ha nessun gruppo primario`
              );
            } */
          }
        } else {
          // cerco il gruppo primary di REGISTRY_ID
          tmp = registry.registry_groups.find(
            (g) =>
              g.pivot.registry_id === REGISTRY_ID &&
              g.pivot.primary.value === "Y"
          );
          if (tmp) {
            ret = tmp.code;
          } /* else {
            console.info(
              `contraente ${REGISTRY_ID} non ha nessun gruppo primario`
            );
          } */
        }
        break;
      case "compagnia":
        ret = !hasVariousAccounting
          ? `${ancillary.insurance_policy.insurer.code}-${ancillary.insurance_policy.insurer.title}`
          : "-";
        break;
      case "communication":
        // "0":"E-mail",
        // "1":"Posta",
        // "2":"SMS",
        // "3":"Telefono",
        // "4":"Tramite Capogruppo",
        // "5":"No Avviso"
        // in base al valore, dovrò mettre l'icona:

        // or "blank"
        // ret = `<b-icon icon="blank" title="NP"/>`;
        ret = [
          {
            icon: "blank",
            title: "NP",
          },
        ];

        if (!REGISTRY_ID || !registry) {
          return ret;
        }

        if (registry) {
          const primary_address_book = registry.address_books.find(
            (address_book) => address_book.status_addressbook.value === 0
          );
          if (primary_address_book?.attributables?.CELL) phone = true;
          if (primary_address_book?.attributables?.MAIL) email = true;
        }

        if (!hasVariousAccounting) {
          if (ancillary.insurance_policy.attributables.NONOT === "Y") {
            policy_no_notify = true;
          }

          if (ancillary.insurance_policy.registry_group_id) {
            // cerco il code tra i gruppi in registryies..

            // DEBUG
            if (!registry.registry_groups) {
              console.debug(
                `>>>  QID: ${item.id} -> contraente ${REGISTRY_ID} non ha relazione coi gruppi`
              );
              registry["registry_groups"] = [];
            }
            tmp = registry;
            if (tmp && tmp.registry_groups && tmp.registry_groups.length) {
              grp = tmp.registry_groups.find(
                (g) => (g.id = ancillary.insurance_policy.registry_group_id)
              );
              if (grp) {
                ret[0].icon =
                  communication_icons[grp.pivot.communication_type.value];
                ret[0].title = grp.pivot.communication_type.text;

                if (
                  (grp.pivot.communication_type.value === 0 && !email) ||
                  (grp.pivot.communication_type.value === 2 && !phone)
                ) {
                  ret[0].red = true;
                }

                // se è tramite capogruppo: cerco il capogruppo e setto il suo communication_type
                if (grp.pivot.communication_type.value === communication_type) {
                  c = getCoordinatorCommunicationType(grp);
                  if (c != null) {
                    ret.push(c);
                  }
                }
              }
            }
          } else {
            // cerco il gruppo primary di REGISTRY_ID
            // DEBUG
            if (!registry.registry_groups) {
              console.debug(
                `>>>  QID: ${item.id} -> contraente ${REGISTRY_ID} non ha relazione coi gruppi`
              );
              registry["registry_groups"] = [];
            }
            tmp = registry;
            if (tmp && tmp.registry_groups && tmp.registry_groups.length) {
              grp = tmp.registry_groups.find(
                (g) => g.pivot.primary.value === "Y"
              );
              if (grp) {
                ret[0].icon =
                  communication_icons[grp.pivot.communication_type.value];
                ret[0].title = grp.pivot.communication_type.text;

                if (
                  (grp.pivot.communication_type.value === 0 && !email) ||
                  (grp.pivot.communication_type.value === 2 && !phone)
                ) {
                  ret[0].red = true;
                }

                // se è tramite capogruppo: cerco il capogruppo e setto il suo communication_type
                if (grp.pivot.communication_type.value === communication_type) {
                  c = getCoordinatorCommunicationType(grp);
                  if (c != null) {
                    ret.push(c);
                  }
                }
              } else {
                // console.info(
                //   `contraente ${REGISTRY_ID} non ha nessun gruppo primario`
                // );

                // ritorno il CHAN di comunicazione del contraente
                ret[0].icon = chan_icons[tmp.attributables.CHAN];
                ret[0].title = tmp.attributables.CHAN;

                if (
                  (tmp.attributables.CHAN === "E-mail" && !email) ||
                  (tmp.attributables.CHAN === "SMS" && !phone)
                ) {
                  ret[0].red = true;
                }
              }
            } else {
              // console.info(
              //   `contraente ${REGISTRY_ID} non ha nessun gruppo primario`
              // );
              ret[0].icon = chan_icons[tmp.attributables.CHAN];
              ret[0].title = tmp.attributables.CHAN;

              if (
                (tmp.attributables.CHAN === "E-mail" && !email) ||
                (tmp.attributables.CHAN === "SMS" && !phone)
              ) {
                ret[0].red = true;
              }
            }
          }

          if (policy_no_notify) {
            ret[0].icon = communication_icons[4];
            ret[0].title = "Polizza esclusa da avviso di scadenza";
            ret[0].red = true;
          }
        } else {
          // FIXME registry_groups "dovrebbe" sempre esserci, quindi qui non andrebbe generato = [] !!!
          if (!registry.registry_groups) {
            console.debug(
              `>>>  QID: ${item.id} -> contraente ${REGISTRY_ID} non ha relazione coi gruppi`
            );
            registry["registry_groups"] = [];
          }
          tmp = registry.registry_groups.find(
            (g) => g.pivot.primary.value === "Y"
          );
          if (tmp) {
            ret[0].icon =
              communication_icons[tmp.pivot.communication_type.value];
            ret[0].title = tmp.pivot.communication_type.text;

            if (
              (tmp.pivot.communication_type.value === 0 && !email) ||
              (tmp.pivot.communication_type.value === 2 && !phone)
            ) {
              ret[0].red = true;
            }

            // se il metodo è tramite capogruppo: cerco il capogruppo e setto il suo communication_type
            if (tmp.pivot.communication_type.value === communication_type) {
              c = getCoordinatorCommunicationType(tmp);
              if (c != null) {
                ret.push(c);
              }
            }
          } else {
            // console.info(
            //   `contraente ${REGISTRY_ID} non ha nessun gruppo primario`
            // );
            // ritorno il CHAN di comunicazione del contraente
            ret[0].icon = chan_icons[registry.attributables.CHAN];
            ret[0].title = registry.attributables.CHAN;

            if (
              (registry.attributables.CHAN === "E-mail" && !email) ||
              (registry.attributables.CHAN === "SMS" && !phone)
            ) {
              ret[0].red = true;
            }
          }
        }

        default_chan =
          store.getters["auth/settings"]?.attribute_DCHAN_value ?? false;

        if (
          (ret[0].icon === "blank" || typeof ret[0].icon === "undefined") &&
          default_chan !== false
        ) {
          ret[0].icon = communication_icons[default_chan];
          ret[0].title = "Canale di comunicazione di default";
          ret[0].default = true;
        }

        break;
      case "ancillary_code":
        ret = !hasVariousAccounting ? ancillary.code.value : "";
        break;
      case "name2":
        // ret = `${toInfoData(registry, "registryfullname", null)}</br>
        // ${toRelationString(
        //   "correspondences",
        //   registry.correspondences,
        //   ["NAME2"],
        //   [1]
        // )} </br>
        // ${toInfoData(registry, "address", 1, "\n")}`;
        ret = toInfoData(registry, "registryfullname", null);
        tmp = toRelationString(
          "correspondences",
          registry.correspondences,
          ["NAME2"],
          [1]
        );
        if (tmp) {
          ret += `</br>${tmp}`;
        }
        tmp = toInfoData(registry, "address", 1, "\n");
        if (tmp) {
          ret += `</br>${tmp}`;
        }
        break;
      case "contraente":
        // ret = !hasVariousAccounting
        //   ? ancillary.insurance_policy.registry.status_registry.value == 1
        //     ? `${ancillary.insurance_policy.registry.attributables.CNAM} ${ancillary.insurance_policy.registry.attributables.CREG}`
        //     : `${ancillary.insurance_policy.registry.attributables.SURN} ${ancillary.insurance_policy.registry.attributables.NAME}`
        //   : "BOH";
        console.log(REGISTRY_ID);
        if (!REGISTRY_ID) {
          return "";
        }
        // ret = toInfoData(registry, "registry", null, "\n");
        // (nome cognome / ragione sociale) + corrispondenza (domicilio)
        ret = `${toInfoData(
          registry,
          "registryfullname",
          null,
          "\n"
        )}\n${toInfoData(registry, "address", 1, "\n")}`;
        break;
      case "residenza":
        // if (!hasVariousAccounting) {
        //   if (ancillary.insurance_policy.registry.correspondences) {
        //     tmp = ancillary.insurance_policy.registry.correspondences.filter(
        //       (el) => [0].includes(el.status_correspondence.value)
        //     );
        //     ret = tmp.length === 1 ? tmp[0].attributables["ADDR"] : "--";
        //   }
        // } else {
        //   ret = "BOH";
        // }
        if (!REGISTRY_ID) {
          return "";
        }
        ret = toInfoData(registry, "address", null, "\n");
        break;
      case "info":
        // TODO: SOLO SE hasBookEntry:
        // confrontare gross con la somma dei data[0].book_entry.entry_details[2].gross; dove la cassa è 3 (sospesi: SS)
        // Se i 2 valori sono diversi faccio vedere la tabella

        // TODO: se campo modified, allora metto la bandierina rossa;

        // se la polizza ha un gruppo, prendo communication_type dalla registry che ha l'id del contraente
        // "0":"E-mail",
        // "1":"Posta",
        // "2":"SMS",
        // "3":"Telefono",
        // "4":"Tramite Capogruppo",
        // "5":"No Avviso"
        // in base al valore, dovrò mettre l'icona:

        // or "blank"
        // ret = `<b-icon icon="blank" title="NP"/>`;
        ret = {
          notes: notes_tooltip(notes),
          notes_counter: notes_counter(notes),
          amount: amount_tooltip(insurance_payment),
          flag: flag_tooltip(insurance_payment),
          premium: flag_premium(insurance_payment),
        };
        // TODO implement the logic
        break;
      case "contatti":
        if (!hasVariousAccounting) {
          ret = toInfoData(
            ancillary.insurance_policy.registry,
            "address_books",
            null,
            "\n"
          );
        } else {
          ret = toInfoData(registry, "address_books", null, "\n");
        }
        if (!ret) {
          ret = "-";
        }
        ret = ret.replace(/,/g, ", ");
        break;
      case "attributables.CREG":
      case "attributables.NINO":
      case "attributables.CHAN":
      case "attributables.DOB":
      case "attributables.SEX":
        tmp = virtualField.split(".");
        ret = tmp.reduce((acc, part) => acc && acc[part], registry);
        break;
      case "attributables.NOB":
      case "attributables.NOR":
        tmp = virtualField.split(".");
        tmp = tmp.reduce((acc, part) => acc && acc[part], registry);
        ret = countries.find((e) => e.id == tmp).title;
        break;
      case "attributables.POB":
      case "attributables.CITY":
      case "attributables.ISPL":
        tmp = virtualField.split(".");
        tmp = tmp.reduce((acc, part) => acc && acc[part], registry);
        ret = councils.find((e) => e.id == tmp).title;
        break;
      default:
        // ret = toLocaleCurrency(0);
        console.info("WARNING: unknown virtualField ", virtualField);
        ret = "";
    }
  } else {
    console.info(
      "WARNING: payments getVirtualValue - data or ancillary or ancillary.insurance_policy is undefined"
    );
  }
  return ret;
};

export const renderDbRowData = (field, data) => {
  let ret = "-";
  let val;
  switch (field.value) {
    case "ancillary_code":
    case "gross_premium":
    case "compagnia":
    case "contatti":
    case "number":
    case "status_policy":
    case "contraente":
    case "residenza":
    case "produttore":
    case "collaborazione":
    case "mora":
    case "expired_at":
    case "covered_at":
    case "suspended_at":
    case "info":
    case "payment_splitting":
    case "attributables.CREG":
    case "attributables.NINO":
    case "attributables.CHAN":
    case "attributables.POB":
    case "attributables.DOB":
    case "attributables.SEX":
    case "branch_risk":
      ret = getVirtualValue(field.value, data);
      break;
    // case "gross":
    //   ret = toLocaleCurrency(data[field]);
    //   break;
    // CHECK valore mancante!!!
    case "periodo":
    case "errore":
    case "agenzia":
      ret = "manca nel response";
      break;
    default:
      // valore direttamente dal db row
      // if (data[field]) {
      // val = ["string", "number"].includes(typeof data[field])
      //   ? data[field]
      //   : data[field].text;
      val = texts(field, data);
      // console.log("Val: ", val);
      ret = db_currency_fields.includes(field.value)
        ? toLocaleCurrency(val)
        : val;
      // }
      break;
  }
  return ret;
};

export const notes_counter = (array) => {
  let ret = {
    header: "Quantità",
    content: array.length,
  };
  return ret;
};

const getOwner = (value) => {
  if (!value) return "-";
  return (
    store.state.auth.users.find((user) => user.id === value).username ?? "-"
  );
};

export const notes_tooltip = (array) => {
  // crea un nuovo attributo "entity" nel array originale
  for (let item of array) {
    item["entity"] = getTaskableLabel(item.pivot.taskable_type);
  }
  if (array.length) {
    array.forEach((note) => {
      if (!Object.prototype.hasOwnProperty.call(note, "owner"))
        note.owner = getOwner(note.owner_id);
      if (note.last_update.indexOf("-") > 0)
        note.last_update = toLocaleDate(
          note.last_update,
          "DD/MM/YYYY HH:mm:ss"
        );
      if (note.created_at.indexOf("-") > 0)
        note.created_at = toLocaleDate(note.created_at, "DD/MM/YYYY HH:mm:ss");
    });
  }
  return {
    header: "Note (tooltip)",
    // content: toTooltipTask(array, ""),
    content: toGroupedTooltipTask(array),
    rawData: array,
  };
  // // TODO : volendo, in base al pivot.taskable_type, col pivot.taskable_id posso creare uno shortcut
  // // al tab che a sua volta dipende dal task_type
  // return {
  //   header: "Note",
  //   content: toGroupedTooltipTask(array),
  //   // content: pippo(),
  //   rawData: array,
  //   // rawData: null,
  // };
};

export const flag_tooltip = (data) => {
  let histories = data.histories.filter(
    (history) =>
      history.method === "PUT" &&
      Object.hasOwnProperty.call(history.new, "status_payment")
  );
  if (!data) return;
  let ret;
  if (histories.length) {
    ret = {
      header: "Forzata",
      content: "Importo modificato manualmente",
    };
  }
  return ret;
};

export const flag_premium = (data) => {
  if (!data.payable) return;
  let histories = data.histories.filter(
    (history) =>
      history.method === "PUT" &&
      Object.hasOwnProperty.call(history.new, "gross")
  );
  let ret;
  if (histories.length) {
    ret = {
      header: "Importo modificato manualmente",
      content: `Vecchio premio: ${toLocaleCurrency(
        data.payable.gross
      )}\nNuovo Premio: ${toLocaleCurrency(
        histories[histories.length - 1].new.gross
      )}\nUtente: ${
        store.state.auth.users.find(
          (user) => user.id === histories[histories.length - 1].user_id
        ).username
      }\nData e Ora Modifica: ${toLocaleDate(
        histories[histories.length - 1].last_update,
        "DD/MM/YYYY HH:mm:ss"
      )}`,
    };
  }
  return ret;
};

export const amount_tooltip = (insurance_payment) => {
  if (!insurance_payment) return;
  let ret;
  let histories = insurance_payment.histories.filter(
    (history) =>
      history.method === "PUT" &&
      Object.hasOwnProperty.call(history.new, "gross")
  );
  if (histories.length) {
    let old_amount =
      insurance_payment?.payable?.insurance_policy?.gross_premium; // ultimo;
    let new_amount = insurance_payment?.payable.gross; // premio
    if (old_amount != new_amount) {
      let last_update = moment(insurance_payment.payable.last_update).format(
        "DD/MM/YYYY"
      );
      let content = `<table class="amount-tooltip">
      <!--
      <thead>
        <tr>
          <th scope="" colspan="2">Totale Modificato</th>
        </tr>
      </thead>
      -->
      <tbody>
        <tr>
          <!-- <th scope="row">Vecchio importo</th> -->
          <td>Vecchio importo</td>
          <td>${toLocaleCurrency(old_amount)}</td>
        </tr>
        <tr>
          <!-- <th scope="row">Nuovo importo</th> -->
          <td>Nuovo importo</td>
          <td>${toLocaleCurrency(new_amount)}</td>
        </tr>
        <tr>
          <!-- <th scope="row">Data</th> -->
          <td>Data</td>
          <td>${last_update}</td>
        </tr>
      </tbody>
    </table>
    `;
      ret = {
        header: "Totale Modificato",
        content,
      };
    }
  }
  return ret;
};
// TODO valutare se meglio aggiungere un case in getVirtualValue
export const getRiskTypeDescription = (item) => {
  let ret;
  switch (item.insurance_risk.risk_branch.risk_type.code) {
    case "V":
      ret = item.title ? item.title : "";
      break;
    case "D":
      ret = item.insurance_risk.title ? item.insurance_risk.title : "";
      break;
    case "A":
      ret =
        item.attributables["REPL"] || item.attributables["MODL"]
          ? `${item.attributables["REPL"] ? item.attributables.REPL : ""} ${
              item.attributables["MODL"] ? item.attributables.MODL : ""
            }`
          : "Polizza auto";
  }
  return ret;
};

export const perc = (data, ancillary) => {
  const def = 0;
  const res = (
    (parseFloat(data.gross) /
      parseFloat(ancillary.insurance_policy.gross_premium)) *
      100 -
    100
  ).toFixed(2);
  return isNaN(res) ? def.toFixed(2) : res;
};
